import React from "react"
import { Link } from "gatsby"
import CommitteeMembers from "./committee"
const MembersPage = () => {
  return (
    <>
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>KYTS Team</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>KYTS Team</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CommitteeMembers />
    </>
  )
}

export default MembersPage
