import React, { Component } from "react"
import axios from "axios"
import { Link } from "gatsby"
import { Form } from "reactstrap"
import apiRequest from "../../hooks/HttpRequest"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//import PaypalExpressBtn from 'react-paypal-express-checkout';
// import { PayPalButton } from "react-paypal-button-v2";
import PayPalBtn from '../../components/paypal/paypalButton'
import DataTable from "react-data-table-component"
import UseHttpRequest from "../../hooks/HttpRequestCommiteeMembers"
import { faThermometerQuarter } from "@fortawesome/free-solid-svg-icons"


let gridColumns
export default class CommitteeMembers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arrayMembersFiltered: [],
      arrayMembers: [],
      arrayCommitteeYears: []
    }
  }

  componentDidMount() {
    gridColumns = [
      {
        name: "Year",
        selector: "CommitteeYear",
        sortable: true,
      },
      {
        name: "Position",
        selector: "CommitteePosition",
        cell: (row) => <span>{row.CommitteePosition.toString().substring(row.CommitteePosition.toString().indexOf(".") + 1)}</span>,
        sortable: true,
      },
      {
        name: "Name",
        selector: "FName",
        cell: (row) => <span>{row.FName + " " + row.LName}</span>,
      }
    ]

    this.getPastCommitteeMembers()

    var arraylocalCommitteeYears = [];
    for (var i = 2004; i <= (new Date()).getFullYear() + 3; i += 3) {
      //console.log((i - 1) + " - " + (i + 1));
      arraylocalCommitteeYears.push((i - 1) + " - " + (i + 1));
    }
    this.setState({
      arrayCommitteeYears: arraylocalCommitteeYears,
    });
  }


  getPastCommitteeMembers() {
    axios
      .get(apiRequest.baseURL + "/Members/GetAllMembers", apiRequest.jsonHeader)
      .then((response) => {
        console.log(response.data.Members.filter((member) => member.IsCommitteeMember === true));

        this.setState({
          arrayCommitteeMembers: response.data.Members,
          arrayMembersFiltered: response.data.Members.filter((member) => member.IsCommitteeMember === true && member.CommitteeYear === (new Date()).getFullYear()).sort((a, b)=>a.CommitteePosition>b.CommitteePosition?1:-1)
        })
      })
      .catch((e) => {
        toast.error(e)
      })
  }



  //handles form inputs change events
  handleChange = (e) => {
    console.log(e.target.value);
    if (e.target.value.trim().length > 0) {
      var localMembersFiltered = this.state.arrayCommitteeMembers
      console.log(this.state.arrayCommitteeMembers);
      if (e.target.type === "checkbox") {
        this.setState({ [e.target.name]: e.target.checked ? true : false })
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          arrayMembersFiltered: this.state.arrayCommitteeMembers.filter(
            (Member) => Member.CommitteeYear >= parseInt(e.target.value.toString().split('-')[0].trim()) && Member.CommitteeYear <= parseInt(e.target.value.toString().split('-')[1].trim())
          )
        })
      }
    }
    else {
      this.setState({
        [e.target.name]: e.target.value,
        arrayMembersFiltered: this.state.arrayCommitteeMembers
      });
    }
  }

  render() {
    return (
      <>
        <section className="team-section pt-70 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="section-title text-left">
                  <span>
                    <h2 >
                      Committee Members
                    </h2>
                  </span>
                </div>

              </div>
            </div>
            <div className="row">
              <div className="col-3 col-lg-3 ml-auto mb-3">
                <div className="team-btn text-right">
                  <Link to="/past-committee" className="optional-btn">
                    View Past Committee
                    <i className="flaticon-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.arrayMembersFiltered.map((item, index) => (
                <div key={index} className="col-3 col-md-3 col-lg-3 mb-4">
                  <div className="single-team-member">
                    <div className="team-image">
                      <img src={item.PhotoURL} alt="" />
                    </div>

                    <div className="member-content">
                      <h3>{item.FName}</h3>
                      <span>{item.CommitteePosition.toString().substring(item.CommitteePosition.toString().indexOf(".") + 1)}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    )
  }
}
