import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Members from "../components/about/members";
const memberslist = () => {
  return (
    <Layout>
      <SEO
        title="Committee Members | Kentucky Tamil Sangam"
        desc="Committee Members | Kentucky Tamil Sangam"
        pathname="/kyts-members"
        keywords="kyts members"
      />
      <Members />
    </Layout>
  )
}

export default memberslist
